export const En = {
    uah: "UAH",
};

export const Uk = {
    uah: "грн",
};

export default {
    En,
    Uk,
    "en-US": En,
    "uk-UA": Uk,
}