<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3 scrollable"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.orderId="{item}">
            <div class="order-id-wrapper">{{item.orderId}}</div>
        </template>
        <template v-slot:item.endDate="{item}">{{ formatShortDate(item.endDate) }}</template>

        <template v-slot:item.paymentStatus="{item}">
            <payment-status
                :payment="item"
                @update="$emit('update')"
            />
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import PaymentStatus from "@/components/common/rework-admissions/PaymentStatus";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        components: {
            PaymentStatus,
        },
        mounted() {
            this.headers = this.headers.map(h => {
                h.text = this.$t(h.text);

                return h;
            });
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'student.paymentStatus', value: 'paymentStatus', sortable: false},
                {text: 'OrderId', value: 'orderId', sortable: false},
                {text: 'student.description', value: 'description', sortable: false},
                {text: 'student.amount', value: 'amount', sortable: true},
                {text: 'student.currency', value: 'currency', sortable: false},
                {text: 'student.paymentDate', value: 'endDate', sortable: false},
                {text: 'student.card', value: 'senderCardMask', sortable: false},
            ],
            formatShortDate,
        }),
    }
</script>
<style lang="scss">
    .order-id-wrapper {
        word-break: break-all;
    }
</style>