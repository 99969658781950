<template>
    <div>
        <v-alert
            v-if="paymentAlert"
            outlined
            :type="paymentAlertType"
            prominent
            border="left"
            dismissible
            @input="onDismissedAlert"
        >
            <v-row align="center">
                <v-col class="grow">
                   {{paymentAlertMessage}}
                </v-col>
                <v-col class="shrink">
                    <v-btn to="paymentHistory">Історія платежів</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        
        <v-card v-if="!deanEmitsPayableAdmission" :elevation="6" class="mb-2">
            <v-container grid-list-md fluid fill-height>
                <template v-if="selectedReworks.length === 0">
                    <v-layout style="min-height: 64px;" xs12 md12 align-center>
                        <div class="payment-text-placeholder grey--text">
                            {{$t('student.paymentMsg1')}}
                            <v-icon color="red darken-2">font_download</v-icon>
                            {{$t('student.paymentMsg2')}}
                        </div>
                    </v-layout>
                </template>

                <template v-if="selectedReworks.length > 0">
                    <v-layout xs12 md3 align-center>
                        <form
                                @submit.prevent="onSubmit()"
                                ref="liqPayForm"
                                method="POST"
                                action="https://www.liqpay.ua/api/3/checkout"
                                accept-charset="utf-8">
                            <input
                                    type="hidden"
                                    name="data"
                                    :value="payment.liqPayData"/>
                            <input type="hidden" name="signature" :value="payment.liqPaySignature"/>
                            <v-btn :loading="paymentSendLoading"
                                   type="submit"
                                   color="primary"
                            >
                                {{$t('student.pay')}}
                            </v-btn>
                        </form>
                        <div class="ml-2">
                            <img
                                height="50px"
                                :src="require('../../../assets/liqpay.png')"
                                alt="LiqPay"
                            >
                        </div>
                        <div>
                            <v-btn @click="dialogLiqPayTermsAndConditions = true" large icon color="orange darken-3"><v-icon>help</v-icon></v-btn>
                        </div>
                    </v-layout>
                    <v-layout xs12 md4>
                        <span class="title">
                            <span class="font-weight-light">{{$t('student.choosenAbs')}}: </span> 
                            {{selectedReworks.length}}
                        </span>
                    </v-layout>
                    <v-layout xs12 md4>
                        <span class="title">
                            <span class="font-weight-light">{{$t('student.totalReworkPrice')}}: </span>
                            <span>{{payment.liqPayAmmount.toFixed(2)}}</span> {{getCurrency()}}
<!--                            <span>+ {{reworkFixedFeePrice}} {{getCurrency()}} <span class="body-2">(комісія за обробку платежу)</span></span>-->
                        </span>
                    </v-layout>
                </template>
            </v-container>
        </v-card>
        
        <div class="mt-8 mb-3">
            <admission-types-legend/>
        </div>
        
        <div class="mb-2">
            <v-btn-toggle
                v-model="filterType"
                @change="onFilterTypeChange"
                borderless
            >
                <v-btn value="all">
                    <span class="hidden-sm-and-down">Всі заборгованості</span>

                    <v-icon right>
                        done_outline
                    </v-icon>
                </v-btn>

                <v-btn value="absence" color="red" dark>
                    <span class="hidden-sm-and-down">Тільки пропуски</span>

                    <v-icon right color="white">
                        font_download
                    </v-icon>
                </v-btn>

                <v-btn value="unsatisfactory" color="blue" dark>
                    <span class="hidden-sm-and-down">Тільки "2"</span>

                    <v-icon right color="white">
                        filter_2
                    </v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
        
        <absents-grid
                :items="items"
                :total="total"
                :loading="loading"
                :deanEmitsPayableAdmission="deanEmitsPayableAdmission"
                @paginate="onPaginate"
                @input="onInputGrid"
                @update="updateGrid"
                @addNewAttachment="onAddNewAttachment($event)"
                @getAttachment="onGetAttachment($event)"
        >
        </absents-grid>
        
        <v-dialog v-model="dialogUploadAttachment" max-width="600px">
            <upload-attachment
                    v-if="dialogUploadAttachment"
                    :item="dialogUploadItem"
                    @saved="onSavedAttachment"
                    @shouldClose="dialogUploadAttachment = false"
            ></upload-attachment>
        </v-dialog>

        <v-dialog v-model="dialogUploadAttachmentView" max-width="1000px">
            <show-attachment
                    v-if="dialogUploadAttachmentView"
                    :item="dialogUploadItemView"
                    @shouldClose="dialogUploadAttachmentView = false"
                    @modifyAttachment="onAddNewAttachment($event)"
            ></show-attachment>
        </v-dialog>

        <v-dialog v-model="dialogLiqPayTermsAndConditions" max-width="1000px">
            <liq-pay-terms-and-conditions
                v-if="dialogLiqPayTermsAndConditions"
                @shouldClose="dialogLiqPayTermsAndConditions = false"
            ></liq-pay-terms-and-conditions>
        </v-dialog>
    </div>
</template>

<script>
    import AbsentsGrid from "./AbsentsGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import {getCurrency} from "../../../helpers/currencyHelper";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import UploadAttachment from "./UploadAttachment";
    import ShowAttachment from "./ShowAttachment";
    import {paymentStatusesEnum} from "@/constants/paymentStatuses";

    import {AppConfig} from "../../../config/configFactory";
    import config from "../../../app.config";
    import LiqPayTermsAndConditions from "@/components/student/academicAbsents/LiqPayTermsAndConditions.vue";

    let configuration = AppConfig(config);

    export default {
        components: {
            ShowAttachment,
            AbsentsGrid,
            AdmissionTypesLegend,
            UploadAttachment,
            LiqPayTermsAndConditions,
        },
        mounted() {
            this.loading = true;

            HttpService.getInstance()
                .get(`payment/getReworkFixedFeePrice`)
                .then(reworkFixedFeePrice => {
                    this.loading = false;
                    
                    this.reworkFixedFeePrice = reworkFixedFeePrice;
                })
            
            this.paymentSendLoading = false;

            const paymentStatus = this.$route.query && this.$route.query.paymentStatus ? this.$route.query.paymentStatus.toLowerCase() : null;
            
            switch (paymentStatus) {
                case "new":
                    this.paymentAlertType = "warning";
                    this.paymentAlertMessage = "Новий платіж";
                    break;
                    
                case "finished":
                    this.paymentAlertType = "success";
                    this.paymentAlertMessage = "Платіж успішно проведено!";
                    break;
                    
                case "aborted":
                    this.paymentAlertType = "warning";
                    this.paymentAlertMessage = "Проведення платежу перервано!";
                    break;
                    
                case "failure":
                    this.paymentAlertType = "error";
                    this.paymentAlertMessage = "Виникла помила при проведенні платежу.";
                    break;
                    
                case "waitconfirmation":
                    this.paymentAlertType = "warning";
                    this.paymentAlertMessage = "Платіж очікує на підтвердження...";
                    break;
                    
                case "inprogress":
                    this.paymentAlertType = "warning";
                    this.paymentAlertMessage = "Платіж в процесі проведення...";
                    break;
            }

            if (paymentStatus) {
                this.paymentAlert = true;
            }
        },
        data: () => {
            return {
                dialogUploadAttachment: false,
                dialogUploadItem: null,

                dialogUploadAttachmentView: false,
                dialogUploadItemView: null,
                
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                
                payment: {
                    liqPayData: '',
                    liqPaySignature: '',
                    liqPayAmmount: 0,
                    liqPayOrderId: null,
                },

                paymentSendLoading: false,
                selectedReworks: [],

                getCurrency,

                deanEmitsPayableAdmission: false,
                
                paymentAlert: false,
                paymentAlertType: "error",
                paymentAlertMessage: "",

                paymentStatusesEnum,

                filterType: "all",

                dialogLiqPayTermsAndConditions: false,

                reworkFixedFeePrice: null,
            }
        },
        methods: {
            documentUrl(document) {
                return `${configuration.apiEndpoint}/file/get/document/${document}`;
            },
            onSubmit() {
                this.paymentSendLoading = true;

                HttpService.getInstance({}, {}, true)
                    .post("payment/getDataSignature", this.selectedReworks)
                    .then(resp => {
                        this.payment = {
                            liqPayData: resp.data,
                            liqPaySignature: resp.signature,
                            liqPayAmmount: resp.ammount,
                            liqPayOrderId: resp.orderId,
                        };
                    })
                    .then(() => {
                        this.$refs.liqPayForm.submit();
                        
                        setTimeout(() => {
                            this.paymentSendLoading = false;
                        }, 100);
                    })
                    .catch(() => {
                        this.paymentSendLoading = false;
                    })
            },
            onInputGrid(items) {
                this.selectedReworks = items;

                this.payment.liqPayAmmount = items
                    .map(i => i.reworkPrice)
                    .reduce(
                        function add(a, b) {
                            return a + b;
                        },
                    0);
            },
            getItems(queryParams, filterType) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentRework/${filterType}?${queryParams}`)
                    .then(reworks => {
                        this.items = reworks.items;
                        this.total = reworks.total;
                        this.loading = false;
                    });
                
                HttpService.getInstance()
                    .get('studentRework/getReworkAdmissionsSettings')
                    .then(resp => {
                        this.deanEmitsPayableAdmission = resp.deanEmitsPayableAdmission;
                    }) 
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData), this.filterType);
                }

                this.pagination = paginationData;
            },
            updateGrid() {
                this.getItems(objectQueryStringify(this.pagination), this.filterType);
            },
            onSavedAttachment() {
                this.dialogUploadAttachment = false;
                
                this.updateGrid();
            },
            onAddNewAttachment(item) {
                this.dialogUploadItem = item;
                this.dialogUploadAttachment = true;
                
                this.dialogUploadAttachmentView = false;
            },
            onGetAttachment(item) {
                this.dialogUploadItemView = item;
                this.dialogUploadAttachmentView = true;
            },
            onDismissedAlert() {
                this.paymentAlert = false;
                this.$router.replace("absents")
            },
            onFilterTypeChange(event) {
                this.getItems(objectQueryStringify(this.pagination), this.filterType);
            }
        },
    }
</script>