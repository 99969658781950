<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-row
                        align="center"
                        justify="center"
                >
                    <v-card>
                        <v-card-title>Виберіть студента, щоб переглянути аккаунт</v-card-title>
                        <v-card-text>
                            <v-tabs
                                    v-model="tab"
                            >
                                <v-tab key="all">
                                    Глобальний пошук
                                </v-tab>
                                <v-tab key="byGroup">
                                    Уточнююючий пошук
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item key="all">
                                    <v-container>
                                        <v-layout
                                                text-center
                                                wrap
                                        >
                                            <v-flex xs12>
                                                <v-container grid-list-md>
                                                    <v-layout wrap>
                                                        <v-autocomplete
                                                                :items="studentsAll"
                                                                v-model="selectedStudent"
                                                                item-text="value"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Студент"
                                                        ></v-autocomplete>
                                                    </v-layout>
                                                </v-container>
                                                <v-flex v-if="selectedStudent" xs12 class="mt-6">
                                                    <v-btn @click="putData()" :disabled="loadingSave" large dark
                                                           color="red darken-2">Перейти
                                                    </v-btn>
                                                </v-flex>

                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-tab-item>

                                <v-tab-item key="byGroup">
                                    <v-container>
                                        <v-layout
                                                text-center
                                                wrap
                                        >
                                            <v-flex xs12>
                                                <v-container grid-list-md>
                                                    <v-layout v-if="!loading" wrap>
                                                        <v-select
                                                                :items="faculties"
                                                                v-model="selectedFaculty"
                                                                item-text="nameUk"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Факультет"
                                                        ></v-select>
                                                    </v-layout>
                                                    <v-layout v-if="!loading && selectedFaculty" wrap>
                                                        <v-select
                                                                :items="specialities"
                                                                v-model="selectedSpeciality"
                                                                item-text="nameUk"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Спеціальність"
                                                        ></v-select>
                                                    </v-layout>
                                                    <v-layout v-if="!loading && selectedSpeciality" wrap>
                                                        <v-select
                                                                :items="semesters"
                                                                v-model="selectedSemester"
                                                                item-text="value"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Семестер"
                                                        ></v-select>
                                                    </v-layout>
                                                    <v-layout v-if="!loading && selectedSemester" wrap>
                                                        <v-select
                                                                :items="academicGroups"
                                                                v-model="selectedAcademicGroup"
                                                                item-text="value"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Академічна група"
                                                        ></v-select>
                                                    </v-layout>
                                                    <v-layout v-if="!loading && selectedAcademicGroup" wrap>
                                                        <v-autocomplete
                                                                :items="students"
                                                                v-model="selectedStudent"
                                                                item-text="value"
                                                                item-value="id"
                                                                return-object
                                                                solo
                                                                label="Студент"
                                                        ></v-autocomplete>
                                                    </v-layout>
                                                </v-container>
                                                <v-flex v-if="selectedStudent" xs12 class="mt-6">
                                                    <v-btn @click="putData()" :disabled="loadingSave" large dark
                                                           color="red darken-2">Перейти
                                                    </v-btn>
                                                </v-flex>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import {rolesEnum} from "../../constants/rolesEnum";
    import {remapErrors} from "../../helpers/errorsHelper";

    export default {
        data: () => ({
            tab: 'all',

            studentsAll: [],

            loading: false,
            loadingSave: false,

            faculties: [],
            specialities: [],
            semesters: [],
            academicGroups: [],
            students: [],

            selectedFaculty: null,
            selectedSpeciality: null,
            selectedSemester: null,
            selectedAcademicGroup: null,
            selectedStudent: null,
        }),
        mounted() {
            const roles = this.getUserRole();

            if (roles.indexOf(rolesEnum.admin) > -1) {

                HttpService.getInstance()
                    .get("faculties/getAll")
                    .then(resp => {
                        this.loading = false;
                        this.faculties = resp
                    });
            } else {
                this.$router.push({name: "studentMarks"}).catch(err => {});
            }

        },
        watch: {
            selectedFaculty: function (newFaculty, oldFaculty) {
                if (newFaculty !== oldFaculty && newFaculty) {
                    HttpService.getInstance()
                        .get(`specialities/getAllByFaculty/${newFaculty.id}`)
                        .then(resp => {
                            this.specialities = resp;

                            this.selectedSpeciality = null;
                            this.selectedSemester = null;
                            this.selectedAcademicGroup = null;
                        });
                }
            },
            selectedSpeciality: function (newSpeciality, oldSpeciality) {
                if (newSpeciality !== oldSpeciality && newSpeciality) {
                    HttpService.getInstance()
                        .get(`semester/semesterBySpeciality/${newSpeciality.id}`)
                        .then(resp => {
                            this.semesters = resp.map(r => {
                                r.value = `${r.yearNumber} курс (${r.semesterNumber} семестр)`;

                                return r;
                            });

                            this.selectedSemester = null;
                            this.selectedAcademicGroup = null;
                        });
                }
            },
            selectedSemester: function (newSemester, oldSemester) {
                if (newSemester !== oldSemester && newSemester) {
                    HttpService.getInstance()
                        .get(`academicGroup/getAll/${this.selectedFaculty.id}/${this.selectedSpeciality.id}/${this.selectedSemester.id}`)
                        .then(resp => {
                            this.academicGroups = resp.map(r => {
                                r.value = `${r.groupNumber} група`;

                                return r;
                            });

                            this.selectedAcademicGroup = null;
                        });
                }
            },
            selectedAcademicGroup: function (newAcademicGroup, oldAcademicGroup) {
                if (newAcademicGroup !== oldAcademicGroup && newAcademicGroup) {
                    HttpService.getInstance()
                        .get(`student/getAllByAcademicGroup/${this.selectedAcademicGroup.id}`)
                        .then(resp => {
                            this.students = resp.map(r => {
                                r.value = `${r.surname} ${r.name} ${r.patronymic}`;

                                return r;
                            });
                        });
                }
            },
            tab: function (newTab, oldTab) {
                const roles = this.getUserRole();

                if (newTab !== oldTab && newTab === 0 && roles.indexOf(rolesEnum.admin) > -1) {
                    HttpService.getInstance()
                        .get(`student/getAll`)
                        .then(resp => {
                            this.studentsAll = resp.map(r => {
                                r.value = `${r.surname} ${r.name} ${r.patronymic}`;

                                return r;
                            });
                        });
                }
            }
        },
        methods: {
            getUserRole() {
                return this.$auth.getRoles();
            },
            putData() {
                this.loadingSave = true;

                HttpService.getInstance()
                    .put("superAdmin/setStudent", this.selectedStudent)
                    .then(resp => {
                        this.loadingSave = false;

                        this.$router.push({name: "studentMarks"}).catch(err => {});
                    })
                    .catch(err => {
                        this.loadingSave = false;
                    });
            }
        }
    }
</script>