<template>
    <v-card class="elevation-3 mt-4">
        <v-card-text>
            <v-container grid-list-md>

                <v-layout wrap class="my-2 subtitle-1 font-weight-light">
                    <v-flex xs6 class="text-xs-right">Поточний бал</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.marksValueCurrent != null ? moduleInfo.marksValueCurrent : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Бал за модульний контроль</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.moduleControlPoints != null ? moduleInfo.moduleControlPoints : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Загальний бал</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.totalPoints != null ? moduleInfo.totalPoints : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Традиційна оцінка</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.totalTraditional != null ? moduleInfo.totalTraditional : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Оцінка ECTS</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.totalAbc != null ? moduleInfo.totalAbc : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap align-center class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Статус</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        <v-chip v-if="moduleInfo.stateType != null" color="primary" text-color="white">
                            {{getModuleStatus(moduleInfo.stateType)}}
                        </v-chip>
                        <div v-if="moduleInfo.stateType === null">-</div>
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 subtitle-1 font-weight-light">
                    <v-flex xs6 class="text-xs-right">Дата</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{moduleInfo.classDate != null ? formatShortDate(moduleInfo.classDate) : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 subtitle-1 font-weight-light">
                    <v-flex xs6 class="text-xs-right">Викладач</v-flex>
                    <v-flex xs6 class="font-weight-bold">{{moduleInfo.professor != null ?
                        `${moduleInfo.professor.surname}
                        ${moduleInfo.professor.name.charAt(0)}. ${moduleInfo.professor.patronymic.charAt(0)}.` : ""}}
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import moduleMarkStatuses from "../../../constants/moduleMarkStatuses";

    export default {
        data: () => ({
            moduleInfo: {
                marksValueCurrent: null,
                moduleControlPoints: null,
                totalPoints: null,
                totalTraditional: null,
                totalAbc: null,
                stateType: null,
                classDate: null,
                professor: null,
            },
            formatShortDate,
        }),
        props: {
            activeModule: {
                type: Object,
                default: () => ({
                    id: null,
                }),
            }
        },
        mounted() {
            this.getData();
        },
        watch: {
            "activeModule.id": function (newModule, oldModule) {
                if (newModule !== undefined && newModule !== oldModule) {
                    this.getItems();
                }
            }  
        },
        methods: {
            getData() {
                HttpService.getInstance()
                    .get(`StudentModule/getInfo/${this.activeModule.id}`)
                    .then(resp => {
                        this.moduleInfo = resp;
                    });
            },
            getModuleStatus(moduleStatusId) {
                const moduleStatus = moduleMarkStatuses.find(m => m.id == moduleStatusId);

                if (moduleStatus) {
                    return moduleStatus.nameUk;
                }

                return "";
            }
        },
    }
</script>