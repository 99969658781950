<template>
    <v-navigation-drawer
        fixed
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
        :value="drawer"
        @input="$emit('toggleDrawer', $event)"
        :width="300"
    >
        <v-list dense class="mt-4" expand>
            <navigation-list-template :items="studMenuItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import NavigationListTemplate from '../../common/NavigationListTemplate';
import {mapMutations} from "vuex";

export default {
    props: {
        drawer: {
            type: Boolean,
        }
    },
    components: {
        'navigation-list-template': NavigationListTemplate,
    },
    mounted() {
        this.studMenuItems = this.remapItems(this.studMenuItemsRaw);
    },
    methods: {
        ...mapMutations(['setTopNavigationItem']),
        translateItems(items) {
            return items.map(i => {
                i.name = this.$t(i.nameConst);

                return i;
            });
        },
        remapItems(items) {
            let itemsRemapped = items.slice(0);

            return itemsRemapped.map(item => {
                item.name = this.$t(item.nameConst);
                
                if (item.children !== undefined) {

                    item.children = item.children.map(ch => {
                        if (this.$route.params.subject && ch.id.toString() === this.$route.params.subject.toString()) {

                            this.setTopNavigationItem(ch);
                        }

                        return ch;
                    });
                }

                if (item.route && item.route.name && this.$route.name === item.route.name) {
                    this.setTopNavigationItem(item);
                }

                return item;
            })
        }
    },
    data: () => ({
        studMenuItems: [],
        studMenuItemsRaw: [
            {
                icon: 'list',
                nameConst: 'eAristoStudent.subjectList',
                name: 'Дисципліни',
                route: {name: "eAristoStudentSubjectsList"}
            },
            {divider: true},
            {
                icon: 'bar_chart',
                nameConst: 'eAristoStudent.statistics',
                name: 'Статистика проходження',
                route: {name: "eAristoStudentStatistics"}
            },
        ]
    })
}
</script>