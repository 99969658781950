<template>
    <div>
        <student-marks-calendar-selector :selected-year="year" :selected-month="month" @change="onChangeMonth"/>
        <student-marks-calendar :monthYear="{year, month}"/>
    </div>
</template>

<script>
    import StudentMarksCalendar from "./StudentMarksCalendar";
    import StudentMarksCalendarSelector from "./StudentMarksCalendarSelector";
    import MarkInner from "../../../common/MarkInner";

    export default {
        data: () => ({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
        }),
        components: {
            StudentMarksCalendar,
            StudentMarksCalendarSelector,
            MarkInner,
        },
        methods: {
            onChangeMonth(input) {
                this.year = input.year;
                this.month = input.month;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-event {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 2px;
        background-color: #1867c0;
        color: #ffffff;
        border: 1px solid #1867c0;
        width: 100%;
        font-size: 12px;
        padding: 3px;
        cursor: pointer;
        margin-bottom: 1px;
    }
</style>