<script>
    import {Pie, mixins} from 'vue-chartjs';

    const {reactiveProp} = mixins;

    export default {
        extends: Pie,
        mixins: [reactiveProp],
        props: {
            chartData: {
                type: [Object, null]
            },
            options: {
                type: Object,
            }
        },
        mounted() {
            this.renderChart(this.chartData, this.options);
        },
        watch: {
            chartData: {
                handler: function(newChartData) {
                    this.renderChart(newChartData, this.options);
                },
                deep: true,
            }
        }
    }
</script>