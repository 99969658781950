<template>
    <div>
        <student-marks-calendar-selector :selected-year="year" :selected-month="month" @change="onChangeMonth"/>
        <student-marks-by-months :monthYear="{year, month}"/>
    </div>
</template>

<script>
    import StudentMarksCalendarSelector from "../marksCalendar/StudentMarksCalendarSelector";
    import StudentMarksByMonths from "./StudentMarksByMonths";

    export default {
        data: () => ({
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
        }),
        components: {
            StudentMarksCalendarSelector,
            StudentMarksByMonths,
        },
        methods: {
            onChangeMonth(input) {
                this.year = input.year;
                this.month = input.month;
            }
        }
    }
</script>
