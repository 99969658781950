<template>
    <v-container class="register-container" style="position: relative">
        <block-loader v-if="loading"/>
        <v-layout justify-center>
            <v-flex xs12>
                <table class="scrollable marks-table elevation-3 mb-6">
                    <thead>
                    <tr>
                        <th width="350" class="cell-header font-weight-light">
                            {{$t('student.moduleName')}}
                        </th>
                        <th class="cell-header font-weight-light">
                            {{$t('student.currentPoints')}}
                        </th>
                        <th class="cell-header font-weight-light">
                            {{$t('student.averageMark')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.moduleControlMark')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.totalMark')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.totalMarkClassic')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.totalMarkECTS')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.date')}}
                        </th>
                        <th width="150" class="cell-header font-weight-light">
                            {{$t('student.professor')}}
                        </th>
                    </tr>
                    </thead>
                   
                    <tbody>
                    <tr v-if="moduleInfo" v-for="moduleInfo in modulesInfo">
                        <td class="module-overview-cell-student">
                            {{`${moduleInfo.module.orderBy}. ${moduleInfo.module.nameUk}`}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.marksValueCurrent ? moduleInfo.marksValueCurrent.toFixed(2) : ""}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.marksValueAverage !== null ? moduleInfo.marksValueAverage.toFixed(2) : ""}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.moduleControlPoints}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.totalPoints}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.totalTraditional}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{moduleInfo.totalAbc}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{formatShortDate(moduleInfo.classDate)}}
                        </td>
                        <td class="module-overview-cell-student">
                            {{getProfessorInitials(moduleInfo)}}
                        </td>
                    </tr>
                    <tr class="subject-row font-weight-bold">
                        <td colspan="4" class="module-overview-cell-student">За дисципліну</td>
                        <td class="module-overview-cell-student">{{subjectInfo.totalPoints}}</td>
                        <td class="module-overview-cell-student">{{subjectInfo.totalTraditional}}</td>
                        <td class="module-overview-cell-student">{{formatShortDate(subjectInfo.classDate)}}</td>
                        <td class="module-overview-cell-student">{{getProfessorInitials(subjectInfo)}}</td>
                    </tr>
                    </tbody>
                </table>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import BlockLoader from "../../../common/BlockLoader";
    import {formatShortDate} from "../../../../helpers/dateHelper";
    
    export default {
        components: {
            BlockLoader
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                modulesInfo: [],
                subjectInfo: {},
                loading: false,
                formatShortDate
            }
        },
        mounted() {
           this.getData();
        },
        watch: {
            "$route.params.subject": function (newSubject, oldSubject) {
                if (newSubject !== undefined && newSubject !== oldSubject) {
                    this.getData();
                }
            }  
        },        
        methods: {
            getData() {
                this.loading = true;
                HttpService.getInstance()
                    .get(`studentMarks/getModulesOverview/${this.$route.params.subject}`)
                    .then(resp => {
                        this.modulesInfo = resp;
                    })
                    .then(() => {
                        return HttpService.getInstance()
                            .get(`studentMarks/getSubjectOverview/${this.$route.params.subject}`);
                    })
                    .then((subjectInfo) => {
                        this.subjectInfo = subjectInfo;
                        this.loading = false;
                    });
            },
            getProfessorInitials(item) {
                if (item.professor) {
                    return `${item.professor.surname} ${item.professor.name.charAt(0)}. ${item.professor.patronymic.charAt(0)}.`
                }

                return null;
            },
            valueNullToDash(value) {
                if (!value) {
                    return '-';
                }

                return value;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .cell-header {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        min-width: 25px;
        border-bottom: 1px solid #ccc;
    }
    .module-overview-cell-student {
        padding: 10px 10px;
        border-right: 1px solid #ccc;
        text-align: center;
    }
</style>