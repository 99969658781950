<template>
    <div>
        <payment-history-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @update="reloadData()"
        >
        </payment-history-grid>
    </div>
</template>
<script>
    import PaymentHistoryGrid from "./PaymentHistoryGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";

    export default {
        components: {
            PaymentHistoryGrid
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`paymentHistory?${queryParams}`)
                    .then(faculties => {
                        this.items = faculties.items;
                        this.total = faculties.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            reloadData() {
                this.getItems(objectQueryStringify(this.pagination));
            }
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>