<template>
    <div>
        <consultations-and-reworks-grid
                :items="items"
                :total="total"
                :loading="loading"
                :count-of-debts="countOfDebts"
                :sum-of-academic-hours-debts="sumOfAcademicHoursDebts"
                @paginate="onPaginate"
        >
        </consultations-and-reworks-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import ConsultationsAndReworksGrid from "./ConsultationsAndReworksGrid";

    export default {
        components: {
            ConsultationsAndReworksGrid,
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentReworkSchedule/${this.$route.params.subject}?${queryParams}`)
                    .then(reworks => {
                        if (reworks) {
                            this.items = reworks.reworks;

                            this.sumOfAcademicHoursDebts = reworks.sumOfAcademicHoursDebts;
                            this.countOfDebts = reworks.countOfDebts;

                            this.total = reworks.reworks.length;
                        }
                       
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },

                countOfDebts: 0,
                sumOfAcademicHoursDebts: 0,
            }
        }
    }
</script>