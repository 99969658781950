<template>
    <v-navigation-drawer
        fixed
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
        :value="drawer"
        @input="$emit('toggleDrawer', $event)"
        :width="300"
    >
        <v-list dense class="mt-4" expand>
            <navigation-list-template :items="studMenuItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import HttpService from "../../../HttpService";
import NavigationListTemplate from '../../common/NavigationListTemplate';
import {mapMutations} from "vuex";
import isArray from "lodash/isArray";

export default {
    props: {
        drawer: {
            type: Boolean,
        }
    },
    components: {
        'navigation-list-template': NavigationListTemplate,
    },
    mounted() {
        HttpService.getInstance()
            .get("navigationStudent/getSubjectsByStudent")
            .then(res => {
                let subjects = [];

                if (isArray(res)) {
                    subjects = res.map(subject => {
                        const specs = subject.specialities.map(s => s.nameShortUk);

                        return {
                            id: subject.id,
                            name: `${subject.nameUk} (${specs.join(", ")})`,
                            route: {
                                params: {
                                    subject: subject.id,
                                }
                            },
                        };
                    });
                }

                this.studMenuItemsRaw.forEach(s => {
                    if (s.nameConst === "student.marks") {
                        s.children = [...subjects].map(s => {
                            const subj = {
                                ...s,
                                route: {
                                    ...s.route,
                                    name: "studentMarks"
                                }
                            };

                            return subj;
                        });
                    }

                    if (s.nameConst === "student.reworks") {
                        s.children = [...subjects].map(s => {
                            const subj = {
                                ...s,
                                route: {
                                    ...s.route,
                                    name: "consultationsAndReworks"
                                }
                            };

                            return subj;
                        });
                    }
                });

                this.studMenuItems = this.remapItems(this.studMenuItemsRaw);

                this.studMenuItems = this.translateItems(this.studMenuItems);
            });

    },
    methods: {
        ...mapMutations(['setTopNavigationItem']),
        translateItems(items) {
            return items.map(i => {
                i.name = this.$t(i.nameConst);

                return i;
            });
        },
        remapItems(items) {
            let itemsRemapped = items.slice(0);

            return itemsRemapped.map(item => {
                if (item.children !== undefined) {

                    item.children = item.children.map(ch => {
                        if (this.$route.params.subject && ch.id.toString() === this.$route.params.subject.toString()) {

                            this.setTopNavigationItem(ch);
                        }

                        return ch;
                    });
                }

                if (item.route && item.route.name && this.$route.name === item.route.name) {
                    this.setTopNavigationItem(item);
                }

                return item;
            })
        }
    },
    data: () => ({
        studMenuItems: [],
        studMenuItemsRaw: [
            {icon: 'border_color', nameConst: 'student.marks', name: 'Оцінки'},
            {divider: true},
            {
                icon: 'insert_invitation',
                nameConst: 'student.calendar',
                name: 'Календар оцінок',
                route: {name: "studentMarksCalendar"}
            },
            {
                icon: 'format_align_left',
                nameConst: 'student.marksMonthly',
                name: 'Оцінки помісячно',
                route: {name: "studentMarksByMonth"}
            },
            {icon: 'contacts', nameConst: 'student.absents', name: 'Пропуски занять', route: {name: "absents"}},
            // {icon: 'cached', nameConst: 'student.reworks', name: 'Консультації & відпрацювання'},
            {divider: true},
            {icon: 'history', nameConst: 'student.payments', name: 'Історія платежів', route: {name: "paymentHistory"}},
            {divider: true},
            {icon: 'turned_in', nameConst: "studentKrok.spaTraining", route: {name: 'studentSubjectQuiz'}},
            {icon: 'bookmarks', nameConst: "studentKrok.ktiTraining", route: {name: 'studentKtiQuiz'}},
        ]
    })
}
</script>