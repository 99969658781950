<template>
    <v-flex xs12 class="text-xs-right">
        <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :max-width="300"
                offset-x
        >
            <template v-slot:activator="{ on }">
                <a v-on="on">Чому я не бачу прізвища та імена студентів?</a>
            </template>
            <v-card>
                <v-card-text>
                    Можливість переглядати персонифіковані дані про успішність можлива лише, якщо Ви даєте згоду на
                    аналогічну дію. <br>
                    Якщо Ви відкриваєте свої дані, Ви зможете переглядати оцінки персонифіковано лише тих осіб, які
                    також надали на це доступ.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text :to="{name: 'settings'}">Змінити налаштування</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-flex>
</template>
<script>
    export default {
        data: () => ({
            menu: false,
        }),
    }
</script>