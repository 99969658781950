<template>
    <v-container class="register-container" style="position: relative">
        <block-loader v-if="loading"/>
        <v-layout justify-center>
            <why-i-dont-see-surnames/>
        </v-layout>
        
        <v-layout justify-center>
            <v-flex xs12>
                <table class="marks-table elevation-3 mb-6">
                    <thead>
                    <tr>
                        <th class="cell-header font-weight-light"></th>
                        <th width="200" class="cell-header font-weight-light">
                            {{$t('student.pip')}}
                        </th>
                        <th class="cell-header font-weight-light">
                            {{$t('student.averageMark')}}
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(studentFacultyStatistic, index) in studentFacultyStatistics">
                        <td class="module-overview-cell-student">{{index + 1}}.</td>
                        <td class="module-overview-cell-student">
                            {{`${studentFacultyStatistic.surname} ${studentFacultyStatistic.name} ${studentFacultyStatistic.patronymic}`}}
                        </td>
                        <td :class="getCellClass(studentFacultyStatistic.average)">
                            {{studentFacultyStatistic.average ? studentFacultyStatistic.average.toFixed(2) : "-"}}
                        </td>
                    </tr>
                    </tbody>
                </table>

            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import BlockLoader from "../../../common/BlockLoader";
    import findIndex from "lodash/findIndex";
    import WhyIDontSeeSurnames from "../../../common/WhyIDontSeeSurnames";

    export default {
        components: {
            BlockLoader,
            WhyIDontSeeSurnames
        },
        data: () => ({
            studentFacultyStatistics: [],
            loading: false,
        }),
        mounted() {
            this.getData();
        },
        watch: {
            "$route.params.subject": function (newSubject, oldSubject) {
                if (newSubject !== undefined && newSubject !== oldSubject) {
                    this.getData();
                }
            }
        },
        methods: {
            getData(){
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentMarks/getFacultyStatisticsBySubject/${this.$route.params.subject}`)
                    .then(resp => {
                        this.processList(resp);
                        this.studentFacultyStatistics = resp;
                        this.loading = false;
                    });
            },
            getCellClass(markAverage) {
                return `module-overview-cell-student mark mark${Math.round(markAverage)}`;
            },
            processList(studentFacultyStatistics) {
                const itselfRow = findIndex(studentFacultyStatistics, (i) => {
                    return i.isItself;
                });
                
                const length = studentFacultyStatistics.length;
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cell-header {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        min-width: 25px;
        border-bottom: 1px solid #ccc;
    }
    .module-overview-cell-student {
        padding: 10px 10px;
        border-right: 1px solid #ccc;
        text-align: center;
    }
</style>