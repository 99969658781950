<template>
    <v-container fluid>
        <v-layout style="display: block;">
            <absents-index/>
        </v-layout>
    </v-container>
</template>

<script>
    import AbsentsIndex from "../../components/student/academicAbsents/AbsentsIndex";
    
    export default {
        components: {
            AbsentsIndex, 
        }
    }
</script>