<template>
    <marks-tabs>
        <student-overview-mark-tab slot="overview-tab"/>
        <student-mark-tab-content
                slot="markContent" 
                slot-scope="tabScope"
                v-if="tabScope.shouldShow"
                :module="tabScope.module"
                :activeModule="tabScope.activeModule"
        />
    </marks-tabs>
</template>
<script>
    import MarksTabs from "../../common/MarksTabs";
    import StudentMarkTabContent from "./StudentMarkTabContent";
    import StudentOverviewMarkTab from "./StudentOverviewMarkTab";

    export default {
        components: {
            MarksTabs,
            StudentOverviewMarkTab,
            StudentMarkTabContent,
        }
    }
</script>