<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card>
        <v-card-text>
            <v-container v-if="loading" fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                            :size="150"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-container>
            <v-calendar
                    v-if="!loading"
                    :value="todayCalendar"
                    color="primary"
                    :locale="locale"
            >
                <template v-slot:day="{ year, month, day }">
                    <template v-for="mark in getMarksByDate(year, month, day)">
                        <v-menu
                                :key="mark.id"
                                v-model="mark.open"
                                offset-x
                        >
                            <template v-slot:activator="{ on }">
                                <v-chip v-on="on" :color="`${getMarkChipColor(mark)} mark-calendar-item`" text-color="white" class="day-mark-item">
                                    <v-avatar :class="getAvatarColor(mark)">
                                        <mark-inner :mark="mark"></mark-inner>
                                    </v-avatar>
                                    <div>{{mark.subject.nameShortUk}}</div>
                                </v-chip>
                            </template>

                            <v-card>
                                <v-card-text style="max-width: 200px;">
                                    <v-flex xs12>
                                        <v-chip class="subtitle-1"
                                                :color="getAvatarColor(mark)"
                                                text-color="white"
                                        >
                                            <mark-inner :mark="mark"></mark-inner>
                                        </v-chip>
                                    </v-flex>
                                    <v-flex xs12>
                                        <span class="subtitle-1 font-weight-bold">{{$t('student.subject')}}: </span>
                                        {{mark.subject.nameUk}}
                                    </v-flex>
                                    <v-flex xs12>
                                        <span class="subtitle-1 font-weight-bold">{{$t('student.date')}}: </span>
                                        {{formatMarkDate(mark.classDate)}}
                                    </v-flex>
                                    <v-flex xs12>
                                        <span class="subtitle-1 font-weight-bold">{{$t('student.topic')}}: </span>
                                        {{mark.topic.nameUk}}
                                    </v-flex>
                                    <v-flex xs12>
                                        <span class="subtitle-1 font-weight-bold">{{$t('student.professor')}}: </span>
                                        {{`${mark.professor.surname} ${mark.professor.name.charAt(0)}.
                                        ${mark.professor.patronymic.charAt(0)}.`}}
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </template>
                </template>
            </v-calendar>
        </v-card-text>
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../../HttpService";
    import MarkInner from "../../../common/MarkInner";
    import {getMarkChipColor} from "../../../../helpers/markHelper";
    import {formatMarkDate} from "../../../../helpers/dateHelper";
    import isArray from "lodash/isArray";
    
    export default {
        components: { 
            MarkInner,
        },
        props: {
            monthYear: {
                type: Object,
                default: null,
            },
        },
        data: () => ({
            getMarkChipColor,
            formatMarkDate,
            loading: false,
            todayCalendar: null,
            marksByDates: [],
            locale: null,
        }),
        mounted() {
            this.getMarkByDates();
            
            this.locale = this.$i18n.locale;
        },
        watch: {
            monthYear: function(newMonthYear, oldMonthYear) {
                if (newMonthYear.year !== oldMonthYear.year || newMonthYear.month !== oldMonthYear.year) {
                    this.getMarkByDates();
                }
            }  
        },
        methods: {
            getMarkByDates() {
                this.loading = true;
                HttpService.getInstance()
                    .get(`studentMarks/getByMonth/${this.monthYear.month}-01-${this.monthYear.year}`)
                    .then(resp => {
                        this.loading = false;

                        if (isArray(resp)) {
                            this.marksByDates = this.marksByDatesMap(resp);
                        }
                        
                        this.todayCalendar = this.today();
                    });
            },
            marksByDatesMap(input) {
                const marksByDatesMapped = {};
                input.forEach(mark => {
                    marksByDatesMapped[moment(mark.date, "DD.MM.YYYY").format("YYYY-MM-DD")] = mark.marks;
                });
                
                return marksByDatesMapped;
            },
            today() {
                 return moment({year: this.monthYear.year, month: this.monthYear.month - 1, day: 1}).format("YYYY-MM-DD");
            },
            getAvatarColor(mark) {
                return `${this.getMarkChipColor(mark)} darken-4 mark-day`;
            },
            getMarksByDate(year, month, day) {
                const dateMoment = moment({year: year, month: month - 1, day: day}).format("YYYY-MM-DD");
                
                return this.marksByDates[dateMoment];
            }
        },
    }
</script>

<style lang="scss">
    .mark-day {
       margin-right: 5px;    
    }
    
    .day-mark-item {
        padding: 0 5px !important;
        margin-bottom: 2px !important;
    }
    
    .calendar-day-name {
        display: flex;
        text-align: left;
        width: 100%;
    }
    .weekdays {
        margin: 0;
        padding: 10px 0;

        & li {
            display: inline-block;
            width: 13.6%;
            color: #666;
            text-align: center;
            border-right: 1px solid #ccc;
        }
    }

    .days {
        padding: 10px 0;
        margin: 0;

        & li {
            list-style-type: none;
            display: inline-flex;
            width: 13.6%;
            text-align: center;
            margin-bottom: 5px;
            font-size:12px;
            color: #777;
            border-right: 1px solid #ccc;
            
            &.content-day {
                border-top: 1px dashed #ccc;
            }
        }
    }
    
    .marks-list {
        & .mark {
            display: flex;
        }
    }
    
    .mark-calendar-item {
        width: 100%;
    }

    /* Add media queries for smaller screens */
    @media screen and (max-width:720px) {
        .weekdays li, .days li {width: 13.1%;}
    }

    @media screen and (max-width: 420px) {
        .weekdays li, .days li {width: 12.5%;}
        .days li .active {padding: 2px;}
    }

    @media screen and (max-width: 290px) {
        .weekdays li, .days li {width: 12.2%;}
    }
</style>