
<template>
    <v-card class="elevation-3 mt-4">
        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Можливі види робіт</v-flex>
                    <v-flex xs6 class="font-weight-bold subtitle-1">
                        <div v-for="individualWorkType in individualWorkInfo.individualWorkTypes">
                            <v-chip
                                    class="ma-2"
                                    color="primary"
                            >
                                {{individualWorkType.ectsPoints}}
                            </v-chip> {{individualWorkType.nameUk}}
                        </div>
                    </v-flex>
                </v-layout>
                
                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Тип роботи</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{individualWorkInfo.individualWorkType != null ? individualWorkInfo.individualWorkType.nameUk : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 title font-weight-light">
                    <v-flex xs6 class="text-xs-right">Бал за індивідуальну самостійну роботу</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{individualWorkInfo.valueEcts != null ? individualWorkInfo.valueEcts : "-"}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 subtitle-1 font-weight-light">
                    <v-flex xs6 class="text-xs-right">Викладач</v-flex>
                    <v-flex xs6 class="font-weight-bold">{{individualWorkInfo.professor != null ?
                        `${individualWorkInfo.professor.surname}
                        ${individualWorkInfo.professor.name.charAt(0)}. ${individualWorkInfo.professor.patronymic.charAt(0)}.` : ""}}
                    </v-flex>
                </v-layout>

                <v-layout wrap class="my-2 subtitle-1 font-weight-light">
                    <v-flex xs6 class="text-xs-right">Дата</v-flex>
                    <v-flex xs6 class="font-weight-bold">
                        {{individualWorkInfo.classDate != null ? formatShortDate(individualWorkInfo.classDate) : "-"}}
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        data: () => ({
            individualWorkInfo: {
                valueEcts: null,
                classDate: null,
                individualWorkType: null,
                professor: null,
                individualWorkTypes: [],
            },
            formatShortDate,
        }),
        props: {
            activeModule: {
                type: Object,
                default: () => ({
                    id: null,
                }),
            }
        },
        mounted() {
            this.getData();
        },
        watch: {
            "activeModule.id": function (newModule, oldModule) {
                if (newModule !== undefined && newModule !== oldModule) {
                    this.getItems();
                }
            }
        },
        methods: {
            getData() {
                HttpService.getInstance()
                    .get(`StudentIndividualWork/getInfo/${this.activeModule.id}`)
                    .then(resp => {
                        this.individualWorkInfo = resp;
                    });
            },
        },
    }
</script>