import { render, staticRenderFns } from "./StudentMarksList.vue?vue&type=template&id=3341f8dc&scoped=true"
import script from "./StudentMarksList.vue?vue&type=script&lang=js"
export * from "./StudentMarksList.vue?vue&type=script&lang=js"
import style0 from "./StudentMarksList.vue?vue&type=style&index=0&id=3341f8dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3341f8dc",
  null
  
)

export default component.exports