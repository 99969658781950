<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>

        <navigation v-if="$route.name !== 'eAristoStudentSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>

        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
import Toolbar from "../../components/eAristoStudent/common/Toolbar";
import SnackDisplayer from "../../components/common/SnackDisplayer";
import NavigationDrawer from "@/components/eAristoStudent/common/NavigationDrawer";

export default {
    components: {
        Toolbar,
        SnackDisplayer,
        navigation: NavigationDrawer,
    },
    data: () => ({
        drawerApp: true,
    }),
}
</script>
