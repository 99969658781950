<template>
    <div class="ma-3">
        <v-layout justify-center>
            <v-flex xs12>
                <student-marks-calendar-wrapper/>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import StudentMarksCalendarWrapper from "../../components/student/marks/marksCalendar/StudentMarksCalendarWrapper";
    
    export default {
        components: {
            StudentMarksCalendarWrapper, 
        }
    }
</script>