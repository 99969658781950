<template>
    <div>
        <v-card>
            <v-flex xs12>
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        :options.sync="pagination"
                        class="elevation-3 mt-4 scrollable"
                        hide-default-footer
                        no-data-text="Немає оцінок"
                >
                    <template v-slot:item.subject="{item}">{{ `${item.subject.nameUk}` }}</template>
                    <template v-slot:item.markMeanValue="{item}">
                        {{item.markMeanValue.toFixed(2)}}
                    </template>
                    <template v-slot:item.unsatisfactoryCount="{item}">
                        <div v-if="item.unsatisfactoryCount > 0" class="title red--text">{{item.unsatisfactoryCount}}</div>
                        <div v-else>{{item.unsatisfactoryCount}}</div>
                    </template>
                    <template v-slot:item.absenceCount="{item}">
                        <div v-if="item.absenceCount > 0" class="title red--text">{{item.absenceCount}}</div>
                        <div v-else>{{item.absenceCount}}</div>
                    </template>
                    <template v-slot:item.marks="{item}">
                        <div class="marks-wrapper">
                            <div class="mark-outer mr-2 my-1" v-for="mark in item.marks">
                                <student-mark-cell
                                        :mark="mark"
                                        :module="mark.module"
                                />
                            </div>
                            <div v-if="!item.marks.length">-</div>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
        </v-card>
        <v-card class="mt-2">
            <v-container grid-list-md fluid>
                <v-layout wrap>
                    <v-flex xs12 md5>
                        <v-layout wrap>
                            <v-flex xs6 class="text-right title">{{$t('student.averageMark')}}: </v-flex>
                            <v-flex xs6 class="title">{{statistics.markMeanValue.toFixed(2)}}</v-flex>

                            <v-flex xs6 class="text-right">{{$t('student.numberof')}} "5": </v-flex>
                            <v-flex xs6>{{statistics.count5}} ({{statistics.percent5.toFixed(1)}}%)</v-flex>
                            
                            <v-flex xs6 class="text-right">{{$t('student.numberof')}} "4": </v-flex>
                            <v-flex xs6>{{statistics.count4}} ({{statistics.percent4.toFixed(1)}}%)</v-flex>

                            <v-flex xs6 class="text-right">{{$t('student.numberof')}} "3": </v-flex>
                            <v-flex xs6>{{statistics.count3}} ({{statistics.percent3.toFixed(1)}}%)</v-flex>

                            <v-flex xs6 class="text-right">{{$t('student.numberof')}} "2": </v-flex>
                            <v-flex xs6>{{statistics.countUnsatisfactory}} ({{statistics.percentUnsatisfactory.toFixed(1)}}%)</v-flex>

                            <v-flex xs6 class="text-right">{{$t('student.numberof')}} "{{$t('student.abs')}}": </v-flex>
                            <v-flex xs6>{{statistics.countAbsence}} ({{statistics.percentAbsence.toFixed(1)}}%)</v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 md7>
                        <v-layout wrap justify-center>
                            <v-flex xs12 md6>
                                <pie-chart :chart-data="statisticsChart"></pie-chart>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import MarkInner from "../../../common/MarkInner";
    import StudentMarkCell from "../../common/StudentMarkCell";
    import PieChart from "../../../common/statistics/PieChart";

    export default {
        components: {
            MarkInner,
            StudentMarkCell,
            PieChart,
        },
        props: {
            monthYear: {
                type: Object,
                default: null,
            },
        },
        data: () => ({
            headers: [
                {text: 'student.subject', value: 'subject', sortable: false},
                {text: 'student.marks', value: 'marks', sortable: false, width: "50%"},
                {text: 'student.averageMark', value: 'markMeanValue', sortable: false, width: "100px"},
                {text: 'student.numberof2', value: 'unsatisfactoryCount', sortable: false, width: "100px"},
                {text: 'student.numberofabsences', value: 'absenceCount', sortable: false, width: "100px"},
            ],
            items: [],
            loading: false,
            pagination: {
                itemsPerPage: -1,
            },
            
            statistics: {
                count5: 0,
                count4: 0,
                count3: 0,
                countUnsatisfactory: 0,
                countAbsence: 0,

                percent5: 0,
                percent4: 0,
                percent3: 0,
                percentUnsatisfactory: 0,
                percentAbsence: 0,

                markMeanValue: 0,
            },

            statisticsChart: {
                datasets: [
                    {
                        data: []
                    }
                ],
                labels: [
                    'Оцінка "5"', 'Оцінка "4"', 'Оцінка "3"', 'Оцінка "2"', 'нб'
                ]
            },
        }),
        mounted() {
            this.headers.map(t => {
                t.text = this.$t(t.text);
                
                return t;
            });
            this.getMarkByMonthly();

            this.locale = this.$i18n.locale;
        },
        watch: {
            monthYear: function(newMonthYear, oldMonthYear) {
                if (newMonthYear.year !== oldMonthYear.year || newMonthYear.month !== oldMonthYear.year) {
                    this.getMarkByMonthly();
                }
            }
        },
        methods: {
            getMarkByMonthly() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentMarks/getBySubjectMonth/${this.monthYear.month}-01-${this.monthYear.year}`)
                    .then(resp => {
                        this.items = resp;

                        return HttpService.getInstance()
                            .get(`studentMarks/getMarksStatsByMonths/${this.monthYear.month}-01-${this.monthYear.year}`);
                    })
                    .then(resp => {
                        this.loading = false;

                        this.statistics = resp;

                        const newStatChartValue = {
                            data: [
                                this.statistics.percent5.toFixed(2), 
                                this.statistics.percent4.toFixed(2),
                                this.statistics.percent3.toFixed(2),
                                this.statistics.percentUnsatisfactory.toFixed(2),
                                this.statistics.percentAbsence.toFixed(2),
                            ],
                            backgroundColor: ["#4CAF50", "#2196F3", "#FF9800", "#B71C1C", "#F44336"],
                        };

                        this.statisticsChart.datasets.shift();
                        this.statisticsChart.datasets.push(newStatChartValue);
                    })
            },
        }
    }
</script>
<style lang="scss">
    .marks-wrapper {
        display: inline-flex;
        width: 100%;
    }
    .mark-outer {
        display: flex;
    }
</style>