<template>
    <div class="pa-1">
        <div>
            <v-chip :color="getPaymentColor(payment.paymentStatus)" text-color="white">
                {{ getPaymentStatus(payment.paymentStatus) }}
            </v-chip>
        </div>
        <v-btn v-if="payment.paymentStatus !== paymentStatusesEnum.finished"
               small
               color="primary"
               class="mt-2"
               @click="checkStatus(payment.id)"
               :loading="loading"
        >
            Перевірити
        </v-btn>
    </div>
</template>

<script>
import paymentStatuses, {paymentStatusesEnum} from "../../../constants/paymentStatuses";
import HttpService from "../../../HttpService";

export default {
    props: {
        payment: Object,
    },
    data: () => ({
        paymentStatuses,
        paymentStatusesEnum,
        loading: false,
    }),
    methods: {
        checkStatus(paymentId) {
            this.loading = true;

            HttpService.getInstance()
                .get(`payment/checkPaymentStatus/${paymentId}`)
                .then(() => {
                    this.$emit("update");
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getPaymentStatus(paymentStatusId) {
            const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

            if (paymentStatus === undefined) {
                return "";
            }

            return paymentStatus.nameUk;
        },
        getPaymentColor(paymentStatusId) {
            const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

            if (paymentStatus === undefined) {
                return "";
            }

            return paymentStatus.color;
        }
    }
}
</script>