<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3 scrollable absents-data-table"
            v-model="selected"
            :show-select="!deanEmitsPayableAdmission"
            :item-class="topicIsDepartmentRework"
            @input="onInputGrid"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:header.data-table-select="props">
            <v-checkbox
                    class="mt-0"
                    color="primary"
                    hide-details
                    :value="selectAllCheckbox.value"
                    :indeterminate="selectAllCheckbox.indeterminate"
                    @change="checkAllChanged($event)"
            ></v-checkbox>
        </template>
        
        <template v-slot:item.data-table-select="props">
            <v-checkbox
                    v-if="checkIfAbsencePaymentCanBeDone(props.item)"
                    :input-value="checkItemChecked(props.item)"
                    @change="checkItemChanged($event, props.item)"
                    color="primary"
                    class="mt-0"
                    hide-details
            ></v-checkbox>
        </template>

        <template v-slot:item.attachment="{item}">
            <div v-if="checkIfAbsencePaymentCanBeDone(item) && isEmpty(item.attachmentFileName) && item.value === markValuesEnum.absence"
                 class="d-flex justify-center">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on" icon @click="$emit('addNewAttachment', item)">
                            <v-icon>vertical_align_top</v-icon>
                        </v-btn>
                    </template>
                    <span>Завантажити додаток (довідка)</span>
                </v-tooltip>
            </div>
            
            <div v-else-if="!isEmpty(item.attachmentFileName)" class="d-flex justify-center">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on" icon @click="$emit('getAttachment', item)">
                            <v-icon>visibility</v-icon>
                        </v-btn>
                    </template>
                    <span>Переглянути додаток (довідка)</span>
                </v-tooltip>
            </div>
            
            <div v-else class="d-flex justify-center">-</div>

            <div v-if="item.attachmentIsRejected" class="d-flex justify-center">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon color="red" v-on="on">error</v-icon>
                    </template>
                    <span>{{item.attachmentRejectionText}}</span>
                </v-tooltip>
            </div>

        </template>
        
        <template v-slot:item.topic.nameUk="{item}">
            <v-tooltip bottom class="mr-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"
                            color="primary" 
                            v-if="item.topic.isDepartmentReworks">apartment</v-icon>
                </template>
                <span>Дана тема відпрацьовується на кафедрі</span>
            </v-tooltip>
            {{item.topic.nameUk}}
        </template>

        <template v-slot:item.classDate="{item}">
            {{ formatShortDate(item.classDate) }}
        </template>
        <template v-slot:item.reworkPrice="{item}">
            <v-container v-if="item.value === 0">{{ `${item.reworkPrice} ${getCurrency()}` }}</v-container>
            <v-container v-else>-</v-container>
        </template>
        <template v-slot:item.absentType="{item}">
            <div class="d-flex align-center">
                <span v-if="item.value === markValuesEnum.absence" class="pr-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" large color="red darken-2">font_download</v-icon>
                        </template>
                        <span>Пропущене заняття</span>
                    </v-tooltip>
                </span>
                <span v-if="item.value === markValuesEnum.unsatisfactory" class="pr-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" large color="red darken-2">filter_2</v-icon>
                        </template>
                        <span>Незараховане заняття</span>
                    </v-tooltip>
                </span>
                <div>
                    <admission-details
                            class="absence-icon"
                            v-if="item.topicAbsence"
                            :topicAbsence="item.topicAbsence"/>
                </div>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import {getCurrency} from "../../../helpers/currencyHelper";
    import AdmissionDetails from "../../common/rework-admissions/AdmissionDetails";
    import PaymentStatus from "../../common/rework-admissions/PaymentStatus";
    import {paymentStatusesEnum} from "../../../constants/paymentStatuses";
    import {markValuesEnum} from "../../../constants/markValuesEnum";
    import isArray from "lodash/isArray";
    import isEmpty from "lodash/isEmpty";
    import moment from "moment";

    export default {
        components: {
            AdmissionDetails,
            PaymentStatus
        },
        data: () => ({
            markValuesEnum,
            formatShortDate,
            getCurrency,
            paymentStatusesEnum,
            selected: [],

            itemsPaymentCanBeDone: [],

            selectAllCheckbox: {
                value: false,
                indeterminate: false,
            },

            pagination: {
                totalItems: 0,
            },
            headers: [],
            headersList: [
                {text: 'student.attachment', value: 'attachment', sortable: false},
                {text: 'student.department', value: 'department.nameUk', sortable: false},
                {text: 'student.subject', value: 'subject.nameUk', sortable: false},
                {text: 'student.topic', value: 'topic.nameUk', sortable: false},
                {text: 'student.date', value: 'classDate', sortable: false},
                {text: 'student.hours', value: 'topic.academicHours', sortable: false},
                {text: 'student.reworkPrice', value: 'reworkPrice', sortable: false},
                {text: 'student.absenceType', value: 'absentType', sortable: false},
            ],

            isEmpty,
        }),
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
            deanEmitsPayableAdmission: {
                type: Boolean,
            }
        },
        mounted() {
            this.headersList = this.headersList.map(h => {
                h.text = this.$t(h.text);
                
                return h;
            });
            
            this.headers = this.deanEmitsPayableAdmission ? this.headersList.filter(h => h.value !== 'payment' && h.value !== 'reworkPrice') : this.headersList;
        },
        methods: {
            topicIsDepartmentRework(item) {
                console.log("item", item);
                return item.topic.isDepartmentReworks ? 'is-department-rework' : '';
            },
            onInputGrid() {
                this.$emit('input', this.selected);
            },
            checkIfAbsencePaymentCanBeDone(item) {
                return (item.topicAbsence === null && item.isSubjectPayable && item.value === markValuesEnum.absence) || 
                    (item.topicAbsence !== null && item.topicAbsence.payment !== null && item.isSubjectPayable &&
                        (item.topicAbsence.payment.paymentStatus === paymentStatusesEnum.new || item.topicAbsence.payment.paymentStatus === paymentStatusesEnum.failure)) ||
                    (item.topicAbsence !== null && item.isSubjectPayable && !this.getMarkAdmissionValidity(item.topicAbsence));
            },
            getMarkAdmissionValidity(topicAbsence) {
                if (topicAbsence == null) {
                    return false;
                }
                
                const admissionEnd = moment(topicAbsence.admissionEnd);

                return admissionEnd.diff(moment(new Date()), "minutes") > 0 && topicAbsence.isValid;
            },
            checkItemChecked(item) {
              return this.selected.find(s => s.id === item.id);  
            },
            checkItemChanged(event, item) {
                if (event === true) { // checked checkbox
                    this.selected.push(item);

                    this.checkStatusCheckAllCheckbox();
                }
                else {
                    const indexOfItemToRemove = this.selected.indexOf(item);
                    
                    this.selected.splice(indexOfItemToRemove, 1);

                    this.checkStatusCheckAllCheckbox();
                }
            },
            checkAllChanged(event) {
                if (event) {
                    this.selected.push(...this.itemsPaymentCanBeDone);
                    
                    this.selectAllCheckbox.indeterminate = false;
                    this.selectAllCheckbox.value = true;
                }
                else {
                    this.selected = [];
                    this.selectAllCheckbox.indeterminate = false;
                    this.selectAllCheckbox.value = false;
                }
            },
            checkStatusCheckAllCheckbox() {
                if (this.itemsPaymentCanBeDone.length === this.selected.length) {
                    this.selectAllCheckbox.indeterminate = false;
                    this.selectAllCheckbox.value = true;
                }
                else if (this.selected.length > 0 && (this.itemsPaymentCanBeDone.length > this.selected.length)) {
                    this.selectAllCheckbox.indeterminate = true;
                    this.selectAllCheckbox.value = false;
                }
                else {
                    this.selectAllCheckbox.indeterminate = false;
                    this.selectAllCheckbox.value = false; 
                }
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
            deanEmitsPayableAdmission(val, prevVal) {
                this.headers = val ? this.headersList.filter(h => h.value !== 'payment' && h.value !== 'reworkPrice') : this.headersList;
            },
            items(itemsNew, itemsOld) {
                if (isArray(itemsNew)) {
                    this.itemsPaymentCanBeDone = itemsNew.filter(i => {
                        return this.checkIfAbsencePaymentCanBeDone(i);
                    });
                }
            }
        },
    }
</script>

<style lang="scss">
    .absence-icon {
        padding-left: 10px;
        border-left: 1px dashed #CCCCCC;
    }
    
    .absents-data-table table {
        th, td {
            padding: 0 12px !important;
        }
    }
    
    .is-department-rework {
        background: rgba(239, 108, 0, 0.15);
    }
</style>