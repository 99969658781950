<template>
    <div class="ma-3">
        <v-layout justify-center>
            <v-flex xs12>
                <student-marks-by-months-wrapper/>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>

    import StudentMarksByMonthsWrapper from "../../components/student/marks/marksByMonths/StudentMarksByMonthsWrapper";

    export default {
        components: {
            StudentMarksByMonthsWrapper
        }
    }
</script>