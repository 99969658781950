<template>
    <v-card class="mb-2">
        <v-card-text>
            <v-layout align-center justify-center xs12>
                <v-btn-toggle class="month-selector-btn-group" :value="getSelectedMonth">
                    <v-btn class="month-selector-btn" color="info" 
                           @click="$emit('change', {month: month.month, year: month.year})" 
                           :key="index" v-for="(month, index) in monthList" :value="month.monthYear">
                        {{month.name}}
                    </v-btn>
                </v-btn-toggle>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {formatLongMonths} from "../../../../helpers/dateHelper";
    import isArray from "lodash/isArray";

    export default {
        props: {
            selectedMonth: Number,
            selectedYear: Number,
        },
        mounted() {
            HttpService.getInstance()
                .get('studentMarks/getMonthYearSelectors')
                .then(resp => {
                    if (isArray(resp)) {
                        this.monthList = resp.map(m => {
                            return {
                                month: m.month,
                                year: m.year,
                                monthYear: `${m.month}.${m.year}`,
                                name: formatLongMonths({year: m.year, month: m.month, day: 1}),
                            };
                        });   
                    }
                });
        },
        data: () => ({
            monthList: [],
            text: "",
        }),
        computed: {
            getSelectedMonth: {
                get() { 
                    return `${this.selectedMonth}.${this.selectedYear}`;
                },
                set(val) { }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .month-selector {
        & .v-text-field__details {
            display: none;
        }
    }
    .month-selector-btn-group {
        display: block;
        box-shadow: none;
    }
    .month-selector-btn {
        margin-top: 5px !important;
    }
</style>