<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <payment-history-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import PaymentHistoryIndex from "../../components/student/paymentHistory/PaymentHistoryIndex";
    export default  {
        components: {
            PaymentHistoryIndex,
        }
    }
</script>