<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex>
                <transition name="slide">
                    <router-view :key="$route.fullPath"/>
                </transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    components: {},

}
</script>